.App {
  text-align: center;
  padding: 20px;
}

.game-creator {
  margin-bottom: 20px;
}

.game-creator input {
  padding: 8px;
  font-size: 16px;
  width: 200px;
}

.game-creator button {
  padding: 8px 12px;
  font-size: 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
}

.game-stats {
  border: 1px solid #ddd;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f9f9f9;
}

.stats {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stat-item {
  margin: 5px 0;
  display: flex;
  align-items: center;
}

.stat-name {
  width: 70px;
  text-align: right;
  margin-right: 10px;
}

.stat-value {
  width: 30px;
  text-align: center;
}

.stat-item button {
  margin: 0 5px;
  padding: 5px 10px;
  font-size: 14px;
  background-color: #008CBA;
  color: white;
  border: none;
  cursor: pointer;
}

.stat-item button:hover {
  background-color: #007B9A;
}

.game-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.delete-button {
  padding: 5px 10px;
  font-size: 14px;
  background-color: #f44336;
  color: white;
  border: none;
  cursor: pointer;
}

.delete-button:hover {
  background-color: #d32f2f;
}